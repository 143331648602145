import { State } from '../types';
export const prefixCls = 'query-table';

export const baseState: State = {
  id: '',
  filter: {
    schema: {},
  },
  table: {
    columns: [],
  },
  initOptions: { useQuery: true, resultKey: 'data' },
  pagination: { offset: 0, limit: 10, pageSize: 10 },
  resultKey: 'data',
  loadClear: false,
};
