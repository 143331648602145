import * as React from 'react';
import { Image } from 'antd';
import type { ImageProps } from 'antd';
import { CustomColumnComponentProps } from '../../types';

export const InnerImage: React.FC<ImageProps & CustomColumnComponentProps> = ({
  value,
  ...rest
}) => {
  return <Image src={value as string} {...rest} />;
};
