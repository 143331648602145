/* eslint-disable react/display-name */
import * as React from 'react';
import { Submit, Form as AntdForm, FormButtonGroup, FormGrid } from '@formily/antd-v5';
import { createForm } from '@formily/core';
import { createSchemaField } from '@formily/react';
import { cloneDeepWith, isPlainObject } from 'lodash-es';
import { GRID_DEFAULT_OPTION, prefix, addFormilyComponent, totalComponents } from './constant';
import { useFormStorage } from './useFormStorage';
import type { FormProps, FormRef, IFormSchema } from './interface';
import clsx from 'clsx';

const InnerForm = React.forwardRef<FormRef, FormProps>((props, ref) => {
  const {
    schema = {},
    scope,
    formOptions,
    initialValues,
    components,
    grid = {},
    hideAction = false,
    onSubmit: _onSubmit,
    formId,
    storage,
    submitClear = true,
    layout = {},
    className,
  } = props;

  // 创建表单实例
  const form = React.useMemo(() => createForm<any>(formOptions), [formOptions]);

  // 表单存储功能hooks
  const { options: formStorageOptions, clear: clearFromStorage } = useFormStorage(form, {
    formId,
    storage,
    initialValues,
  });

  // 提交功能
  const onSubmit = async (values: any) => {
    const rsp = await _onSubmit?.(values);
    if (rsp === false) {
      return;
    }
    if (submitClear) {
      form.reset();
    }
    if (formId && formStorageOptions.open) {
      clearFromStorage();
    }
  };

  // 导出Ref
  React.useImperativeHandle(ref, () => ({
    getFormInstance: () => form,
    clearStorage: () => clearFromStorage(),
    submit: async () => {
      const values = await form.submit();
      await onSubmit?.(values);
      return values;
    },
  }));

  // grid配置
  const gridOptions = React.useMemo(
    () => ({ ...GRID_DEFAULT_OPTION, ...grid }),
    [JSON.stringify(grid)],
  );

  // schema
  // FromGrid组件写入默认props
  const innerSchema = React.useMemo<IFormSchema>(() => {
    // 使用clone来做深度遍历
    const _schema = cloneDeepWith(schema, (value) => {
      // 设置FromGridProps默认值
      if (isPlainObject(value) && value['x-component'] === 'FormGrid') {
        return {
          ...value,
          'x-component-props': {
            ...gridOptions,
            ...(value['x-component-props'] || {}),
          },
        };
      }
      return undefined;
    });
    return _schema;
  }, [gridOptions, schema]);

  const SchemaField = React.useMemo(() => createSchemaField(), []);
  const formGrid = React.useMemo(() => FormGrid.createFormGrid(gridOptions), [gridOptions]);
  const rejestComponents = React.useMemo(() => ({ ...totalComponents, ...components }), []);
  return (
    <AntdForm
      className={clsx(prefix, className)}
      form={form}
      {...{ labelCol: 7, wrapperCol: 17, ...layout }}>
      <FormGrid grid={formGrid}>
        <SchemaField
          components={rejestComponents}
          schema={{ type: 'object', properties: innerSchema }}
          scope={scope}
        />
        {!hideAction && (
          <FormButtonGroup.FormItem>
            <Submit onSubmit={onSubmit}>提交</Submit>
          </FormButtonGroup.FormItem>
        )}
      </FormGrid>
    </AntdForm>
  );
});

export const Form = InnerForm as typeof InnerForm & {
  addFormilyComponent: typeof addFormilyComponent;
};
Form.addFormilyComponent = addFormilyComponent;
