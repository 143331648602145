export enum RequestStatus {
  /** 等待开始请求  */
  WAITING_START,
  /** 初始化请求 */
  INIT,
  /** 发起请求 */
  START,
  /** 请求成功 */
  SUCCESS,
  /** 请求失败 */
  FAIL,
  /** 重新发起 */
  RESTART,
}

export interface RequestContext {
  /** 请求表示 */
  id: string;
  /** 请求状态 */
  status: RequestStatus;
  /** 请求函数 */
  requestFn: (params: Record<string, any>) => Promise<any>;
  /** 请求参数 */
  data?: Record<string, any>;
  /** 成功数据 */
  response?: any;
  /** 错误信息 */
  error?: any;
}
