import React, { useMemo, useLayoutEffect } from 'react';
import { Table as AntdTable, Tooltip } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { useLocalStorageState } from 'ahooks';
import { prefixCls } from './constants';
import { FaArrowsRotate, FaArrowsLeftRightToLine } from 'react-icons/fa6';
import { QueryTableProps, ActionEnum, RequestStatus, QueryTableRenderComponentType } from './types';
import { useState, useContextDispatch } from './context';
import { useAutoResize } from './hooks';
import { TableRef } from 'antd/es/table';
import clsx from 'clsx';
import { InnerImage as Image } from './components/Image';
import { InnerOperation as Operation } from './components/Operation';

const isInnerType = (type: QueryTableRenderComponentType | string) =>
  ['Image', 'Operation'].includes(type);

const matchInnerComponent = (type: QueryTableRenderComponentType) => {
  const InnerComponents: Record<QueryTableRenderComponentType, any> = {
    Image,
    Operation,
  };
  return InnerComponents[type];
};

export function Table({
  columns: _columns = [],
  dataSource,
  pagination,
  className,
  customColumns,
  tableId = '',
  rowSelection,
  useAutoWidth = false,
  scroll,
  childrens,
  tabsNode,
  extraRightTool,
  defaultTableHandleActions = ['refresh', 'size'],
  ...rest
}: QueryTableProps<AnyObject>) {
  const { requestInfo, table, pagination: paginationData } = useState();
  const dispatch = useContextDispatch();
  const tableRef = React.useRef<TableRef | null>(null);
  const [size, setSize] = React.useState<'middle' | 'small'>('middle');
  const columns = useMemo(() => {
    return _columns.map((col, index) => ({
      ...col,
      dataIndex: col.dataIndex || `dataIndex-${index}`,
    }));
  }, [_columns]);

  // 获取列描述 Key 集合
  const columnsKeys = React.useMemo(
    () => columns.map((col) => String(col.key || col.dataIndex)),
    [columns],
  );

  // 是否开启组队列宽计算 (待有数据后开始初始化)
  const enableAutoWidth = Boolean(useAutoWidth);

  const { autoResizeScroll, rowSelectionWidth } = useAutoResize({
    key: `AUTO_RESIZE_WIDTH_${tableId}`,
    enable: enableAutoWidth,
    rowSelect: !!rowSelection,
    tableRef,
  });

  const onRefresh = () => {
    dispatch({
      type: ActionEnum.REQUEST_START,
      payload: {},
    });
  };

  const onChangeSize = () => {
    setSize((curr) => (curr === 'middle' ? 'small' : 'middle'));
  };

  const actionToMap = React.useMemo(() => {
    return {
      refresh: {
        tooltip: '刷新',
        IconComp: <FaArrowsRotate onClick={onRefresh} className={`${prefixCls}-icon`} />,
      },
      size: {
        tooltip: '大小',
        IconComp: (
          <FaArrowsLeftRightToLine onClick={onChangeSize} className={`${prefixCls}-icon`} />
        ),
      },
      // custom: { tooltip: '自定义列', IconComp: <PivotTable onClick={onRefresh} className={`${prefixCls}-icon`} /> },
    } as Record<string, { tooltip: string; IconComp: JSX.Element }>;
  }, []);

  // 获取默认隐藏列
  const defaultHideColumns = React.useMemo(() => {
    if (typeof customColumns === 'object' && Array.isArray(customColumns?.checkedColumnsKeys)) {
      return columnsKeys.filter((key) => customColumns.checkedColumnsKeys?.indexOf(key) === -1);
    }
    return [];
  }, [customColumns, columnsKeys]);

  // 根据 tableId 获取隐藏列
  const [hideColumns] = useLocalStorageState<string[]>(tableId, {
    defaultValue: defaultHideColumns,
  });

  // 获取「初始」展示列 Keys
  const defaultColumns: string[] = useMemo(() => {
    // 根据 tableId 获取缓存, 计算展示列
    if (tableId) {
      return columnsKeys.filter((key) => hideColumns?.indexOf(key) === -1);
    }
    // 受控展示列 Keys
    if (typeof customColumns === 'object' && Array.isArray(customColumns?.checkedColumnsKeys)) {
      return customColumns.checkedColumnsKeys;
    }
    // 默认展示列 Keys
    return columnsKeys;
  }, [customColumns, hideColumns, columnsKeys, tableId]);

  // 当前展示列 Keys
  const [checkedColumnsKeys] = React.useState<string[]>(defaultColumns || []);

  const tableLoading = useMemo(() => requestInfo?.status === RequestStatus.START, [requestInfo]);

  const magicColumns = useMemo(() => {
    // 遍历处理 column
    const handleMapAction = (col: (typeof columns)['0']) => {
      const { renderType, renderProps = {} } = col;
      // NOTE: 根据 useCustomWidth 开关条件返回数值
      // 普通渲染
      if (!renderType || !isInnerType(renderType)) {
        return {
          ...col,
        };
      }
      // 根据类型渲染
      return {
        ...col,
        // 生成render函数
        render: (value: any, cols: any, index: number) => {
          // 保证用户自定义的组件优先级更高
          const Comp = matchInnerComponent(renderType as QueryTableRenderComponentType);
          return (
            <Comp
              value={value}
              column={cols}
              index={index}
              {...(typeof renderProps === 'function' ? renderProps(cols, index) : renderProps)}
            />
          );
        },
      };
    };

    // 默认原始自定义列逻辑
    if (customColumns) {
      return checkedColumnsKeys
        .map((col) => {
          const item = columns.filter((c) => {
            const filterKey = String(c.key || c.dataIndex || '');
            return col === filterKey;
          })[0];
          return item;
        })
        .filter((item) => item)
        .map(handleMapAction);
    }

    return defaultColumns
      .map((col) => {
        const item = columns.filter((c) => {
          const filterKey = String(c.key || c.dataIndex || '');
          return col === filterKey;
        })[0];
        return item;
      })
      .filter((item) => item)
      .map(handleMapAction);
  }, [checkedColumnsKeys, columns, customColumns, defaultColumns]);

  useLayoutEffect(() => {
    dispatch({
      type: ActionEnum.SAVE_OPTIONS,
      payload: {
        table: {
          ...table,
          checkedColumnsKeys,
        },
      },
    });
  }, [checkedColumnsKeys, dispatch, table]);

  useLayoutEffect(() => {
    dispatch({
      type: ActionEnum.SAVE_OPTIONS,
      payload: {
        table: {
          columns,
        },
      },
    });
  }, [columns, dispatch, table]);

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-action`}>
        <div className={`${prefixCls}-action__left`}>{tabsNode}</div>
        <div className={`${prefixCls}-action__right`}>
          {extraRightTool}
          {defaultTableHandleActions?.map((item: string) => {
            const { tooltip, IconComp } = actionToMap[item];
            return (
              <Tooltip key={item} autoAdjustOverflow title={tooltip}>
                {IconComp}
              </Tooltip>
            );
          })}
        </div>
      </div>
      <AntdTable
        ref={tableRef}
        columns={magicColumns}
        dataSource={dataSource || table?.data || []}
        size={size}
        loading={tableLoading}
        className={clsx(prefixCls, className)}
        pagination={
          typeof pagination === 'boolean'
            ? pagination
            : {
                current: paginationData.current,
                pageSize: paginationData.pageSize,
                total: paginationData.total,
                size: 'default',
                showTotal: (total) => `共${total}条`,
                showQuickJumper: true,
                showTitle: true,
                showSizeChanger: true,
                ...pagination,
                onChange: (pageNumber, pageSize) => {
                  pagination?.onChange?.(pageNumber, pageSize);
                  dispatch({
                    type: ActionEnum.PAGINATION_SET,
                    payload: {
                      offset: (pageNumber - 1) * pageSize,
                      limit: pageSize,
                    },
                  });
                },
              }
        }
        rowSelection={
          rowSelection
            ? {
                ...rowSelection,
                columnWidth: rowSelectionWidth,
              }
            : undefined
        }
        scroll={{
          ...scroll,
          ...autoResizeScroll,
        }}
        {...rest}>
        {childrens}
      </AntdTable>
    </div>
  );
}
