import { observer } from '@formily/react';
import type { IFormFeedback } from '@formily/core';
import { Form, FormGrid, Submit, Reset } from '@formily/antd-v5';
import clsx from 'clsx';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useCollapseGrid } from '../../hooks/useCollapseGrid';
import { prefixCls } from '../../constants';

export interface IQueryFormProps<T extends Record<string, unknown> = any> {
  className?: string;
  gridBaseWidthFrom?: 'screen' | 'form';
  children?: React.ReactNode;
  labelWidth?: number;
  style?: React.CSSProperties;
  mode?: 'normal' | 'unfold';
  maxRows?: number;
  gridMaxColumns?: number;
  onSubmitSuccess?: (values: T) => void;
  onSubmitFailed?: (feedbacks: IFormFeedback[]) => void;
  onReset?: (e: MouseEvent) => void;
}

const basePrefixCls = prefixCls + '-filter';

export const QueryForm: React.FC<React.PropsWithChildren<IQueryFormProps>> = observer((props) => {
  const {
    className,
    gridBaseWidthFrom,
    children,
    labelWidth,
    style,
    mode = 'normal',
    maxRows: baseMaxRows,
    gridMaxColumns,
    onSubmitSuccess,
    onSubmitFailed,
    onReset,
  } = props;
  const { grid, expanded, toggle, shadowRows, maxRows, ref } = useCollapseGrid({
    mode,
    gridBaseWidthFrom,
    baseMaxRows,
    gridMaxColumns,
  });

  return (
    <div className={clsx(basePrefixCls, className)} style={style}>
      <Form
        labelWidth={labelWidth}
        labelWrap
        onAutoSubmit={onSubmitSuccess}
        onAutoSubmitFailed={onSubmitFailed}
        feedbackLayout="terse">
        <div className={`${basePrefixCls}-container`}>
          <div className={`${basePrefixCls}-left`} ref={ref}>
            <div className={`${basePrefixCls}-left-form`}>
              <FormGrid grid={grid}>{children}</FormGrid>
            </div>
          </div>
          <div
            className={clsx(`${basePrefixCls}-right`, {
              [`${basePrefixCls}-right-row`]: shadowRows === 1 || maxRows === 1,
            })}>
            <Submit className={`${basePrefixCls}-right_btn`}>查询</Submit>
            <Reset className={`${basePrefixCls}-right_btn`} onClick={(e) => onReset?.(e as any)}>
              重置
            </Reset>
          </div>
        </div>
      </Form>
      {shadowRows > maxRows && mode === 'normal' && (
        <div
          onClick={() => {
            toggle();
          }}
          className={`${basePrefixCls}-collapse`}>
          {expanded ? (
            <>
              收起条件
              <FaChevronUp size={18} style={{ height: 18 }} />
            </>
          ) : (
            <>
              展开条件
              <FaChevronDown size={18} style={{ height: 18 }} />
            </>
          )}
        </div>
      )}
    </div>
  );
});
