import { isEqual, uniqueId, pick, isNumber, isObject, merge } from 'lodash-es';
import {
  State,
  ActionEnum,
  ActionType,
  IPagination,
  RequestStatus,
  RequestContext,
} from '../types';

interface IProps {
  state: State;
  data?: Record<string, any>;
  pagination: IPagination;
}

export const buildStateByRequest = (props: IProps): State => {
  const { state, data, pagination } = props;
  const requestId = uniqueId('search_table-request_id-');
  const status = RequestStatus.START;
  // 根据filter.data 与 已有数据进行拼接，合并成实际请求参数
  const reqData: Record<string, any> = {
    ...data,
    ...pick(pagination, ['offset', 'limit']),
  };
  if (state.initOptions?.useQuery) {
    state.initOptions?.setUrlParams?.(reqData);
  }

  // 获取所有表单字段
  const graphKeys = Object.keys(state.filter.form?.getFormGraph() || {});
  // 获取所有需要传递的字段
  const graphVars = [
    ...Object.keys(state.initOptions?.params || {}),
    ...[
      ...new Set(
        graphKeys.reduce((prev: string[], key) => [...prev, ...(key.match(/\w+/g) || [])], []),
      ),
    ],
  ];
  const request: RequestContext = {
    id: requestId,
    status,
    requestFn: state.requestInfo?.requestFn!,
    data: graphVars.length > 0 ? pick(reqData, [...graphVars, 'offset', 'limit']) : reqData,
  };
  const pageInfo: IPagination = {
    offset: reqData.offset,
    limit: reqData.limit,
    pageSize: reqData.limit,
  };
  if (isNumber(state.pagination.total)) {
    pagination.total = state.pagination.total;
    pagination.current = Math.floor(pagination.offset / pagination.limit) + 1;
  }

  return {
    ...state,
    requestId: request.id,
    filter: {
      ...state.filter,
      data: {
        ...state.filter.data,
        ...request.data,
      },
    },
    requestInfo: request,
    pagination: {
      ...state.pagination,
      ...pageInfo,
    },
  };
};

export const reducer = (state: State, action: ActionType): State => {
  switch (action.type) {
    // 分页
    case ActionEnum.PAGINATION_SET: {
      // 如果一样则不需要处理
      if (isEqual(action.payload, state.pagination)) {
        return state;
      }
      const pagination = Object.assign(state.pagination, action.payload);
      const data = state.filter.data ?? {};
      // 将数据处理合并到请求参数上
      return buildStateByRequest({ pagination, data, state });
    }
    // 查询
    case ActionEnum.REQUEST_START: {
      if (!isObject(action.payload)) {
        throw new TypeError('search params payload must is Object');
      }
      const payloadData = action.payload;
      // 没有请求函数，不做数据生成
      const data = { ...state.filter?.data, ...payloadData };
      // 重置分页数据
      const pagination = { offset: 0, limit: state.pagination.limit };
      return buildStateByRequest({ state, data, pagination });
    }
    case ActionEnum.REQUEST_INIT_START: {
      let data = { ...state.initOptions?.params };
      let { pagination } = state;
      if (state.initOptions?.useQuery) {
        data = { ...data, ...state.initOptions?.urlParams };
        pagination = merge({}, state.pagination, state.initOptions?.urlParams);
      }
      return buildStateByRequest({ data, pagination, state });
    }
    case ActionEnum.FILTER_RESET: {
      if (!state.requestInfo?.requestFn) {
        return state;
      }
      // 手动执行重置操作
      state.filter?.form?.reset();
      // 清空查询参数
      const data = {
        ...state.initOptions?.params,
      };
      const pagination = { offset: 0, limit: state.pagination.limit };
      const newState = {
        ...state,
        filter: {
          ...state.filter,
          data: {},
        },
      };
      return buildStateByRequest({ state: newState, data, pagination });
    }
    case ActionEnum.REQUEST_RESPONSE_SUCCESS: {
      if (action.payload.id !== state.requestId) {
        return state;
      }
      const { pagination } = state;
      if (isNumber(action.payload.response?.total)) {
        pagination.total = action.payload.response.total;
        pagination.current = Math.floor(pagination.offset / pagination.limit) + 1;
      }

      return {
        ...state,
        requestInfo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-nullish-coalescing
          ...(state.requestInfo! ?? {}),
          status: RequestStatus.SUCCESS,
          response: action.payload.response?.[state.resultKey],
        },
        table: {
          ...(state.table ?? {}),
          data: action.payload.response?.[state.resultKey],
        },
      };
    }
    case ActionEnum.REQUEST_RESPONSE_FAIL: {
      // 请求返回失败数据
      if (action.payload.id !== state.requestId) {
        return state;
      }
      return {
        ...state,
        requestInfo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-nullish-coalescing
          ...(state.requestInfo! ?? {}),
          status: RequestStatus.FAIL,
        },
      };
    }
    case ActionEnum.REQUEST_CANCEL: {
      return {
        ...state,
        requestInfo: {
          ...(state?.requestInfo! ?? {}),
          status: RequestStatus.WAITING_START,
        },
        requestId: undefined,
      };
    }
    case ActionEnum.SAVE_OPTIONS: {
      return merge(state, action.payload);
    }
    case ActionEnum.CLEAR_TABLE_DATA: {
      return { ...state, table: { ...state?.table, data: [] } };
    }
    default: {
      return state;
    }
  }
};
