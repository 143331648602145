import * as React from 'react';
import { Form, onFieldValueChange } from '@formily/core';
import { useLocalStorageState } from 'ahooks';
import { STORAGE_DEFAULT_OPTIONS } from './constant';
import { FormStorage, FormStorageData } from './interface';

const prefix = 'sloth-form';
interface FormStorageConfig {
  /**
   * 表单识别码
   */
  formId?: string;
  /**
   * 表单存储功能配置项
   */
  storage?: Partial<FormStorage> | boolean;
  /**
   * 初始化值用于做存储基准版本号
   */
  initialValues?: Record<string, any>;
}
interface FormStorageResult {
  /**
   * 表单存储功能配置项
   */
  options: FormStorage;
  /**
   * 设置存储数据
   */
  set: (value: FormStorageData) => void;
  /**
   * 清除表单数据
   */
  clear: () => void;
}
export function useFormStorage(form: Form, formConfig: FormStorageConfig): FormStorageResult {
  const { formId, storage, initialValues } = formConfig;
  // storage配置同步
  const options: FormStorage = React.useMemo(() => {
    if (!formId) {
      return { ...STORAGE_DEFAULT_OPTIONS, open: false };
    }
    return typeof storage === 'object'
      ? { ...STORAGE_DEFAULT_OPTIONS, ...storage }
      : { ...STORAGE_DEFAULT_OPTIONS };
  }, [formId, storage]);

  // 是否初始化完成
  const initRef = React.useRef(false);

  // 表单存储数据
  const [storageValue, setStorage] = useLocalStorageState<FormStorageData>(
    formId && storage ? `${prefix}-${formId}` : prefix,
  );

  // 存储表单数据
  React.useEffect(() => {
    const formEffectId = 'form-storage-effect';
    form.addEffects(formEffectId, (_form) => {
      onFieldValueChange('*', () => {
        if (!options.open || !initRef.current) {
          return;
        }
        set({ data: { ..._form.getValuesIn('*') } });
      });
    });
    return () => {
      form.removeEffects(formEffectId);
    };
    // 初始化
  }, []);

  // 写入表单数据
  React.useEffect(() => {
    // 没有开启缓存功能
    if (!options.open) {
      form.setValues(initialValues);
      initRef.current = true;
      return;
    }
    const version = JSON.stringify(initialValues);
    const next: FormStorageData = {};
    // true: 使用缓存数据
    // false: 使用当前数据

    let usePrevCheckData: Promise<boolean | void> = Promise.resolve();
    if (storageValue?.version === undefined) {
      // 没有缓存版本号
      usePrevCheckData = Promise.resolve(false);
    } else if (storageValue?.version === version) {
      // 缓存版本号与当前版本号一致
      usePrevCheckData = Promise.resolve(Boolean(storageValue?.data));
    } else if (!storageValue?.data) {
      // 没有草稿数据
      usePrevCheckData = Promise.resolve(false);
    } else {
      usePrevCheckData = usePrevCheckData.then(() =>
        options.usePrevious(storageValue, { version }),
      );
    }
    usePrevCheckData.then((usePrevData = true) => {
      if (usePrevData) {
        // 使用老版本数据
        form.setValues(storageValue?.data);
        next.version = storageValue?.version;
      } else {
        // 使用新版本数据
        form.setValues(initialValues);
        next.data = undefined;
        next.version = version;
      }
      set(next);
      initRef.current = true;
    });

    // 初始化
  }, []);

  // 设置存储数据
  function set(value: FormStorageData) {
    setStorage((prev: any) => ({ ...prev, ...value }));
  }
  // 清除表单数据
  function clear() {
    set({ data: undefined });
  }
  return { options, set, clear };
}
