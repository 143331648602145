import { Provider, ProviderGlobalDispatch } from './provider';
import { Filter, IQueryFormFilterProps } from './filter';
import { Table } from './table';
import {
  ActionEnum,
  RequestStatus,
  QueryTableFilterISchema,
  QueryTableFilterRef,
  QueryTableFilterISchemaProperties,
  QueryTableProps,
  QueryTableColumnProps,
} from './types';
import './style/index';

export type {
  QueryTableFilterISchema,
  QueryTableFilterRef,
  IQueryFormFilterProps,
  QueryTableFilterISchemaProperties,
  QueryTableProps,
  QueryTableColumnProps,
};
export type { IQueryFormProps } from './components/QueryForm';

export type QueryTableInterface = {
  Provider: typeof Provider;
  ProviderGlobalDispatch: typeof ProviderGlobalDispatch;
  Filter: typeof Filter;
  Table: typeof Table;
  RequestStatus: typeof RequestStatus;
  ActionEnum: typeof ActionEnum;
};
export const QueryTable: QueryTableInterface = {
  Provider,
  ProviderGlobalDispatch,
  Filter,
  Table,
  RequestStatus,
  ActionEnum,
};
