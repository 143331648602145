import { FormItem, FormGrid, FormLayout, FormTab } from '@formily/antd-v5';
import type { JSXComponent } from '@formily/core';
import { FormStorage } from './interface';

/** 组件样式前缀 */
export const prefix = 'lava-form';
/** 默认 Form表单全局组件 */
export const totalComponents: Record<string, JSXComponent> = {
  FormItem,
  FormGrid,
  FormLayout,
  FormTab,
};
/** 全局注入Form组件 */
export function addFormilyComponent(components: Record<string, JSXComponent>) {
  Object.keys(components).forEach((key) => {
    totalComponents[key] = components[key];
  });
}
/** 表单存储功能默认配置项 */
export const STORAGE_DEFAULT_OPTIONS: FormStorage = {
  open: true,
  usePrevious: () => false,
};

/** FormGrid默认配置项 */
export const GRID_DEFAULT_OPTION = {
  maxColumns: 1,
  minWidth: 0,
  maxWidth: 1600,
  rowGap: 16,
  columnGap: 12,
  strictAutoFit: false,
};
