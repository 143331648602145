import { useMemo, useRef, useState, useEffect } from 'react';
import { useSize } from 'ahooks';
import { FormGrid } from '@formily/antd-v5';
import '@formily/grid';

export const useCollapseGrid = ({
  baseMaxRows,
  gridBaseWidthFrom = 'screen',
  mode,
  gridMaxColumns,
}: {
  baseMaxRows?: number;
  gridBaseWidthFrom?: 'screen' | 'form';
  mode?: 'normal' | 'unfold';
  gridMaxColumns?: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const size = useSize(
    gridBaseWidthFrom === 'form' ? ref.current || document.body : document.body,
  ) ?? { width: 0 };
  const [shadowRows, setShadowRows] = useState<number>(0);

  const [maxRows, setMaxRows] = useState<number>(baseMaxRows ?? 2);

  const grid = useMemo(() => {
    const columns = gridMaxColumns ? gridMaxColumns : size.width > 1920 ? 4 : 3;

    return FormGrid.createFormGrid({
      maxColumns: columns,
      minColumns: columns,
      minWidth: 0,
      rowGap: 8,
      columnGap: 12,
      strictAutoFit: false,
      maxRows,
      shouldVisible: (node, _grid) => {
        if (_grid.maxRows === Infinity) return true;

        if ((node.shadowRow || 0) > _grid.maxRows) return false;

        return true;
      },
      onDigest: (_grid) => {
        _grid.options.strictAutoFit = !(_grid.childSize > 2);
        setShadowRows(_grid.shadowRows);
      },
    });
  }, [gridMaxColumns, maxRows, size.width]);

  useEffect(() => {
    if (gridMaxColumns) {
      grid.maxColumns = gridMaxColumns;
    } else if (size.width > 1400) {
      grid.maxColumns = 4;
    } else if (size.width > 900) {
      grid.maxColumns = 3;
    } else if (size.width > 524) {
      grid.maxColumns = 2;
    } else {
      grid.maxColumns = 1;
    }
  }, [size, gridMaxColumns, grid]);

  const expanded = grid.maxRows === Infinity;

  useEffect(() => {
    switch (mode) {
      case 'unfold':
        grid.maxRows = Infinity;
        setMaxRows(Infinity);
        break;
      default:
        break;
    }
  }, [mode]);

  const toggle = () => {
    if (grid.maxRows === Infinity) {
      grid.maxRows = maxRows;
    } else {
      grid.maxRows = Infinity;
    }
  };

  return {
    ref,
    grid,
    expanded,
    toggle,
    shadowRows,
    maxRows,
  };
};
