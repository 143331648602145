import { useMemo, useState } from 'react';
import { Button, Typography, Popover, Space, Spin } from 'antd';
import type { SpaceProps } from 'antd';
import clsx from 'clsx';
import { prefixCls } from '../../constants';

const { Link } = Typography;

// 操作栏支持的配置项
type OperationColumnButtonConfig = {
  /** 按钮文案 */
  label: React.ReactNode;
  /** 按钮是否禁用 */
  disabled?: ((col: Record<string, any>) => boolean) | boolean;
  /** 按钮是否可用 */
  enabled?: ((col: Record<string, any>) => boolean) | boolean;
  /** 按钮是否隐藏 */
  hide?: boolean;
  /** 按钮点击事件 */
  onClick?: (column: Record<string, any>) => void | Promise<void>;
  key?: string;
};

export type InnerOperationProps = {
  /** 操作栏的配置项 */
  buttons: OperationColumnButtonConfig[];
  /** 操作栏的方向 */
  direction: SpaceProps['direction'];
  /** column的数据 */
  column: Record<string, any>;
  /**
   * 设置按钮收起数量
   * @default 4
   * */
  clamp?: number;
};
export const InnerOperation: React.FC<InnerOperationProps> = ({
  buttons: propsButtons = [],
  direction = 'horizontal',
  column,
  clamp = 4,
}) => {
  const buttons: (Omit<OperationColumnButtonConfig, 'disabled'> & {
    disabled: (col: Record<string, any>) => boolean;
  })[] = useMemo(() => {
    return propsButtons.map((item, index) => ({
      ...item,
      key: item.key || `${index}`,
      disabled: (col) => {
        if (typeof item.disabled !== 'undefined') {
          if (typeof item.disabled === 'function') {
            return item.disabled(col);
          }
          return Boolean(item.disabled);
        }
        if (typeof item.enabled !== 'undefined') {
          if (typeof item.enabled === 'function') {
            return !item.enabled(col);
          }
          return !item.enabled;
        }
        return false;
      },
    }));
  }, [propsButtons]);

  // 控制Popover的显隐
  const [showPopover, setShowPopover] = useState(false);

  const [buttonLoading, setButtonLoading] = useState<Record<string, boolean>>({});

  // 判断当前Btn数量是否大于 【clamp】 个,如果大于 【clamp】 个,则显示下拉菜单
  const isVisibleOperations = useMemo(() => buttons.filter((item) => !item?.hide), [buttons]);
  const isClampValid = clamp >= 2 && !Number.isNaN(clamp);
  const clampValue = isClampValid ? clamp : 4;
  const clampCutIdx = clampValue - 1;
  const [showButtons, floatButtons] =
    isVisibleOperations.length <= clampValue
      ? [isVisibleOperations, []]
      : [isVisibleOperations.slice(0, clampCutIdx), isVisibleOperations.slice(clampCutIdx)];

  const onClickButton = async (
    btn: OperationColumnButtonConfig,
    col: Record<string, any>,
    btnDisabled?: boolean,
  ) => {
    if (!btn?.onClick || btnDisabled) {
      return;
    }
    const result = btn?.onClick?.(col);
    if (typeof result?.then === 'function') {
      setButtonLoading((prev) => ({ ...prev, [btn.key || '']: true }));
      Promise.resolve(result).finally(() => {
        setButtonLoading((prev) => ({ ...prev, [btn.key || '']: false }));
      });
    } else {
      setTimeout(() => setShowPopover(false), 150);
    }
  };

  return (
    <Space direction={direction}>
      {showButtons.map((btn, index) => {
        const { label, disabled: disabledHandle, key } = btn;
        const linkDisabled = disabledHandle(column);
        return (
          <Spin spinning={!!buttonLoading[`${index}`]} key={key}>
            <Link
              disabled={linkDisabled}
              style={linkDisabled ? { pointerEvents: 'none' } : {}}
              onClick={() => onClickButton(btn, column, linkDisabled)}>
              {label}
            </Link>
          </Spin>
        );
      })}
      {floatButtons.length > 0 && (
        <Popover
          open={showPopover}
          onOpenChange={setShowPopover}
          className={`${prefixCls}-column-popover`}
          overlayInnerStyle={{ padding: 0 }}
          content={
            <div className={`${prefixCls}-column-menu`}>
              {floatButtons.map((btn) => {
                const { label, disabled: disabledHandle, key } = btn;
                const disabled = disabledHandle(column);
                return (
                  <Spin spinning={!!buttonLoading[key || '']} key={`menu-${key}`}>
                    <Button
                      className={clsx(`${prefixCls}-column-menu-button`, {
                        [`${prefixCls}-column-menu-button-disabled`]: disabled,
                      })}
                      type="text"
                      disabled={disabled}
                      onClick={() => {
                        onClickButton(btn, column, disabled);
                      }}>
                      {label}
                    </Button>
                  </Spin>
                );
              })}
            </div>
          }>
          <Link onClick={() => setShowPopover(true)}>更多</Link>
        </Popover>
      )}
    </Space>
  );
};
