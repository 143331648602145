import * as React from 'react';
import { baseState } from '../constants';
import { ActionType, State } from '../types';

// 存储数据
export const ContextState = React.createContext<State>(baseState);

export const useState = () => React.useContext(ContextState);

// 派发任务
export const ContextDispatch = React.createContext<React.Dispatch<ActionType>>(() => baseState);

export const useContextDispatch = () => React.useContext(ContextDispatch);
