import { useCallback, useMemo, useImperativeHandle, useLayoutEffect, useEffect } from 'react';
import { observer, ISchema, FormProvider, createSchemaField } from '@formily/react';
import { createForm } from '@formily/core';
import type { IFormProps, JSXComponent, IFormFeedback } from '@formily/core';
import { FormItem } from '@formily/antd-v5';
import { useContextDispatch, useState } from './context';
import { isEmpty } from 'lodash-es';
import { prefixCls } from './constants';
import { QueryTableFilterISchemaProperties, QueryTableFilterRef, ActionEnum } from './types';
import { QueryForm, IQueryFormProps } from './components/QueryForm';

export interface IQueryFormFilterProps<T extends Record<string, unknown> = any>
  extends IQueryFormProps {
  schema: QueryTableFilterISchemaProperties;
  /**
   * 用于筛选器表单联动的配置项
   */
  formOptions?: Omit<IFormProps<T>, 'values' | 'initialValues'>;
  /**
   * 筛选器中需要使用的组件
   */
  components?: {
    [key: string]: JSXComponent;
  };
  /**
   * 全局作用域，用于实现协议表达式变量注入
   */
  scope?: any;
  /**
   * 调用内部方法的ref
   */
  ref?: React.RefObject<QueryTableFilterRef>;
}

const filterPrefixCls = prefixCls + '-filter';

export const Filter = observer(
  <T extends Record<string, unknown> = any>(props: IQueryFormFilterProps<T>) => {
    const {
      schema,
      formOptions,
      components,
      scope,
      ref,
      gridBaseWidthFrom = 'screen',
      className,
      style,
      labelWidth,
      onSubmitSuccess,
      onSubmitFailed,
      onReset,
    } = props;

    const dispatch = useContextDispatch();
    const { filter } = useState();

    useImperativeHandle(ref, () => ({
      getFormInstance: () => form,
    }));

    const form = useMemo(() => createForm<any>({ ...formOptions }), []);
    const innerSchema = useMemo<ISchema>(() => {
      const _schema = { ...schema };

      return {
        type: 'object',
        properties: _schema,
      };
    }, [schema]);

    const SchemaField = useMemo(() => {
      return createSchemaField();
    }, []);

    const totalComponents = useMemo(() => {
      if (components?.FormItem) {
        return components;
      }
      return { ...components, FormItem };
    }, [components]);

    const onSearchTableSubmitSuccess = useCallback(
      (values: any) => {
        const graphKeys = Object.keys(form.getFormGraph());
        // 通过FormGraph获取表单存在的使用的字段
        // 字段规则为变量规则，字段只能存在_ 数字 大小写英文字母
        const existValuesKey = [
          ...new Set(
            graphKeys.reduce((prev: string[], key) => [...prev, ...(key.match(/\w+/g) || [])], []),
          ),
        ];

        const payloadData = {} as Record<string, unknown>;

        (existValuesKey || []).forEach((key) => {
          payloadData[key] = values?.[key];
        });

        dispatch({
          type: ActionEnum.REQUEST_START,
          payload: payloadData,
        });

        onSubmitSuccess?.(values);
      },
      [form, onSubmitSuccess],
    );
    const onSearchTableSubmitFailed = useCallback(
      (feedbacks: IFormFeedback[]) => {
        onSubmitFailed?.(feedbacks);
      },
      [onSubmitFailed],
    );

    const onSearchTableReset = useCallback(
      (e: MouseEvent) => {
        dispatch({ type: ActionEnum.FILTER_RESET });
        onReset?.(e);
      },
      [onReset],
    );

    useEffect(() => {
      if (filter?.data) {
        form.setValues(filter?.data);
      }
    }, [filter]);

    useLayoutEffect(() => {
      dispatch({
        type: ActionEnum.SAVE_OPTIONS,
        payload: {
          filter: {
            schema,
            components: totalComponents,
            formOptions,
            form,
            // scope,
          },
        },
      });
    }, [dispatch, form, formOptions, schema, totalComponents, scope]);

    return (
      <div className={`${filterPrefixCls}`}>
        <div
          style={{
            display: isEmpty(innerSchema.properties) ? 'none' : 'block',
          }}
          className={`${filterPrefixCls}-wrapper`}>
          <FormProvider form={form}>
            <QueryForm
              gridBaseWidthFrom={gridBaseWidthFrom}
              onSubmitSuccess={onSearchTableSubmitSuccess}
              onSubmitFailed={onSearchTableSubmitFailed}
              onReset={onSearchTableReset}
              className={className}
              style={style}
              labelWidth={labelWidth}>
              <SchemaField components={totalComponents} schema={innerSchema} scope={scope} />
            </QueryForm>
          </FormProvider>
        </div>
      </div>
    );
  },
  { forwardRef: true },
) as <T extends Record<string, unknown> = any>(
  props: IQueryFormFilterProps<T>,
) => React.ReactElement;
