import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { isObject, omit, assign, isString } from 'lodash-es';
import queryString, { ParseOptions, StringifyOptions } from 'query-string';

type ResultHandle = {
  setValue: (value: Record<string, any>) => void;
  reset: (value?: Record<string, any>) => void;
};

interface Options {
  initValue?: Record<string, any>;
  // 不需要在url中展示的key列表
  excludeKey?: string[];
  parseOptions?: ParseOptions;
  stringifyOptions?: StringifyOptions;
  // 修改url的方式
  type?: 'push' | 'replace';
  // 判断数据是添加还是替换
  append?: boolean;
  // 开启自动转换，
  autoTransform?: boolean;
}

const transforation = (obj: Record<string, any>): Record<string, any> => {
  if (!isObject(obj)) {
    return obj;
  }
  return Object.keys(obj)?.reduce(
    (pre, curr) => {
      const map = { ...pre };
      const value = obj[curr];
      if (isString(value)) {
        if (value === 'true') {
          map[curr] = true;
        } else if (value === 'false') {
          map[curr] = false;
        } else if (!isNaN(Number(value))) {
          map[curr] = Number(value);
        } else {
          map[curr] = value;
        }
      } else if (isObject(value)) {
        map[curr] = transforation(value);
      } else {
        map[curr] = value;
      }
      return map;
    },
    {} as Record<string, any>,
  );
};

const defaultStringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
  arrayFormat: 'bracket',
};

const defaultParseOptions = { arrayFormat: 'bracket' };

export function useSearchParams(
  options?: Options,
): [value: Record<string, any>, handle: ResultHandle] {
  const {
    initValue,
    append = true,
    type = 'push',
    excludeKey = [],
    autoTransform = false,
    ...extra
  } = options ?? {};
  const [currentValue, setCurrentValue] = useState<Record<string, any>>(
    queryString.parse(window.location.search),
  );
  const isFirstValue = useRef<Record<string, any>>();

  const stringifyOptions = useMemo(() => {
    return {
      ...defaultStringifyOptions,
      ...(extra?.stringifyOptions ?? {}),
    } as StringifyOptions;
  }, [extra?.stringifyOptions]);

  const parseOptions = useMemo(() => {
    return {
      ...defaultParseOptions,
      ...(extra?.parseOptions ?? {}),
    } as ParseOptions;
  }, [extra?.parseOptions]);

  const setUrlParams = useCallback(
    (obj: Record<string, any>) => {
      const { origin, pathname, hash } = window?.location ?? {};
      const search = queryString.stringify(omit(obj, excludeKey), stringifyOptions);
      const url = `${origin}${pathname}${search ? `?${search}` : ''}${hash}`;
      if (type === 'push') {
        window.history.pushState(
          { ...window.history.state, url, title: document.title },
          document.title,
          url,
        );
      } else {
        window.history.replaceState(
          { ...window.history.state, url, title: document.title },
          document.title,
          url,
        );
      }
    },
    [type, excludeKey, stringifyOptions],
  );

  const setValue = (value: Record<string, any>) => {
    let map = { ...value };
    if (append) {
      map = assign(map, currentValue);
    }
    setUrlParams(map);
    setCurrentValue(map);
  };

  const reset = (value?: Record<string, any>) => {
    if (value) {
      isFirstValue.current = value;
    }
    if (!isFirstValue.current) return;
    setCurrentValue(isFirstValue.current);
    setUrlParams(isFirstValue.current);
  };

  useEffect(() => {
    const { search } = window?.location ?? {};
    const currentUrlValue = queryString.parse(search, parseOptions);
    let value = autoTransform
      ? transforation(currentUrlValue)
      : (currentUrlValue as Record<string, any>);
    if (initValue) {
      value = assign(currentUrlValue, isObject(initValue) ? initValue : {});
      value = autoTransform ? transforation(value) : value;
      setUrlParams(value);
    }
    setCurrentValue(value);
    if (!isFirstValue.current) isFirstValue.current = value;
  }, [autoTransform]);

  return [currentValue, { setValue, reset }];
}
