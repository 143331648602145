import { useMemo } from 'react';
import type { TableRef, TableProps } from 'antd/es/table';
import { GetConfigOption, SetConfigOption } from './useCustomConfig';

interface IProps {
  key: string;
  // 是否开启自动计算
  enable?: boolean;
  // 是否开启拖拽
  resize?: boolean;
  // 是否开启 行选择（影响总宽计算）
  rowSelect?: boolean;
  // 表格容器引用（用于获取高度）
  tableRef: React.MutableRefObject<TableRef | null>;
  /** 获取缓存列宽 */
  getConfig?: (option: GetConfigOption) => Promise<Record<string, number>>;
  /** 设置缓存列宽 */
  setConfig?: (option: SetConfigOption<Record<string, number>>) => Promise<any>;
}

interface UseAutoResizeType {
  /** 行选择列宽度值  */
  rowSelectionWidth: number;
  /** 列宽总和 */
  autoSizeTotalWidth: number;
  /** 列宽自动计算注册的 TableProps.scroll 属性 */
  autoResizeScroll: TableProps['scroll'];
}

// 选择区 固定宽度
const ROW_SELECTION_COLUMN_WIDTH = 50;

export function useAutoResize(props: IProps): UseAutoResizeType {
  const { enable = false, resize = false, rowSelect = false, tableRef } = props;

  // 选择列宽度
  const rowSelectionWidth = rowSelect ? ROW_SELECTION_COLUMN_WIDTH : 0;

  // 计算总宽
  const autoSizeTotalWidth = useMemo(() => {
    const columnsWidthTotal = 0;

    const computedWidth = columnsWidthTotal + rowSelectionWidth;
    const tableWrap = tableRef?.current?.nativeElement;
    const innerWrap = tableWrap?.getElementsByClassName('sloth-table-content').item(0);
    // 内部实际宽度
    const innerWidth = innerWrap?.getBoundingClientRect()?.width ?? computedWidth;
    return Math.max(innerWidth, computedWidth);
  }, [rowSelectionWidth, tableRef]);

  // 动态 scroll.x 宽度计算
  const autoResizeScroll: TableProps['scroll'] = useMemo(() => {
    return enable
      ? {
          x: resize ? autoSizeTotalWidth || window.innerWidth : window.innerWidth,
        }
      : {};
  }, [autoSizeTotalWidth, enable, resize]);

  return {
    autoSizeTotalWidth,
    rowSelectionWidth,
    autoResizeScroll,
  };
}
