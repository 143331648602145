import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useReducer,
} from 'react';
import { merge, uniqueId } from 'lodash-es';
import { useSearchParams } from 'src/hooks';
import { ActionType, State, RequestStatus, ActionEnum } from './types';
import { baseState } from './constants';
import { ContextState, ContextDispatch } from './context';
import { reducer } from './utils/reducer';

export interface IQueryTableProviderProps<T extends string = 'data'> {
  /** 托管的名称 */
  name?: string;
  /** 查询请求 */
  requestFn: (params: any) => Promise<any>;
  /** 自定义传递的参数 */
  params?: Record<string, unknown>;
  /** 获取的存储到url上 */
  useQuery?: boolean;
  /** 返回数据名称 */
  resultKey?: T;
  /** 分页数据 默认为0 */
  offset?: number;
  /** 页面条数 默认为10 */
  limit?: number;
  /** 发起请求时清空table数据 */
  loadClear?: boolean;
  /** 默认请求 */
  auto?: boolean;
  children?: React.ReactNode;
}

interface IQueryTableProviderRef {
  reload?: () => void;
}

const ProviderGlobalDispatchDefaultName = 'query_table_default';
const ProviderGlobalDispatchMap: Record<string, React.Dispatch<ActionType>> = {};

export const ProviderGlobalDispatch = (
  action: ActionType,
  providerName: string = ProviderGlobalDispatchDefaultName,
) => {
  const dispatch = ProviderGlobalDispatchMap[providerName];
  if (dispatch) {
    dispatch(action);
  }
};

function ProviderComponent<T extends string = 'data'>(
  props: IQueryTableProviderProps<T>,
  ref: React.Ref<IQueryTableProviderRef>,
) {
  const {
    name,
    requestFn,
    useQuery,
    resultKey,
    offset,
    limit,
    loadClear,
    params,
    auto = true,
    children,
  } = props;

  const [urlParams, { setValue }] = useSearchParams({
    append: false,
    autoTransform: true,
  });
  const setUrlParams = (val: Record<string, any>) => {
    setValue(val);
  };

  const initialState: State = merge({}, baseState, {
    id: uniqueId(`query_table-${name ? `${name}-` : ''}provider_id-`),
    resultKey,
    requestInfo: {
      requestFn,
      status: RequestStatus.WAITING_START,
    },
    loadClear,
    initOptions: {
      params,
      resultKey,
      useQuery,
      urlParams,
      setUrlParams,
    },
    pagination: { offset, limit, pageSize: limit },
    filter: {
      schema: {},
      data: useQuery ? { ...params, ...urlParams } : { ...params },
    },
  });
  const [state, dispatch] = useReducer(reducer, initialState);

  // 将name与dispatch绑定
  useEffect(() => {
    const globalName = name || ProviderGlobalDispatchDefaultName;
    ProviderGlobalDispatchMap[globalName] = dispatch;
    return () => {
      delete ProviderGlobalDispatchMap[globalName];
    };
  }, [name]);

  // 属性值变化更新到provider上
  useLayoutEffect(() => {
    dispatch({
      type: ActionEnum.SAVE_OPTIONS,
      payload: {
        resultKey: initialState.resultKey,
        loadClear: initialState.loadClear,
        requestInfo: {
          id: '',
          status: RequestStatus.WAITING_START,
          ...initialState?.requestInfo,
          requestFn,
        },
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (auto) {
      dispatch({ type: ActionEnum.REQUEST_INIT_START });
    }
  }, [auto]);

  useEffect(() => {
    const { requestFn, status, data } = state?.requestInfo ?? {};
    const { loadClear } = state;
    if (!requestFn || !status) return;
    if (![RequestStatus.RESTART, RequestStatus.START].includes(status)) return;
    // 清除table数据
    if (loadClear) {
      dispatch({ type: ActionEnum.CLEAR_TABLE_DATA });
    }
    let result = null as any;
    try {
      result = requestFn({ ...data });
    } catch (error) {
      dispatch({
        type: ActionEnum.REQUEST_RESPONSE_FAIL,
        payload: { ...state.requestInfo, error },
      });
    }
    if (typeof result?.then === 'function') {
      const _result = result as Promise<any>;
      _result
        .then((rsp) => {
          // onReceiveData?.(rsp)
          dispatch({
            type: ActionEnum.REQUEST_RESPONSE_SUCCESS,
            payload: { ...state.requestInfo, response: rsp },
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionEnum.REQUEST_RESPONSE_FAIL,
            payload: { ...state.requestInfo, error },
          });
        });
    } else if (result) {
      // onReceiveData?.(result)
      dispatch({
        type: ActionEnum.REQUEST_RESPONSE_SUCCESS,
        payload: { ...state.requestInfo, response: result },
      });
    }
  }, [state]);

  useImperativeHandle(ref, () => ({
    reload: () => {},
  }));

  return (
    <ContextState.Provider value={state}>
      <ContextDispatch.Provider value={dispatch}>{children}</ContextDispatch.Provider>
    </ContextState.Provider>
  );
}

export const Provider = forwardRef(ProviderComponent) as <T extends string = 'data'>(
  props: IQueryTableProviderProps<T>,
  ref: React.Ref<IQueryTableProviderRef>,
) => JSX.Element;
