import { TableColumnProps } from 'antd';
import { Form, IFormProps } from '@formily/core';
import { IFormGridProps } from '@formily/antd-v5';
import { IQueryTableProviderProps } from '../provider';
import { RequestContext } from './request';
import { QueryTableFilterISchemaProperties } from './schema';

/** 分页 */
export interface IPagination {
  /** 偏移量 */
  offset: number;
  /** 每页数量 */
  limit: number;
  /** 当前页码，从1开始 */
  current?: number;
  /** 每页数量 */
  pageSize?: number;
  /** 总量 */
  total?: number;
}

// 需要存储的数据
// 1. 请求相关
//  1. 存储请求过程中发生的状态修改
//  2. 请求参数变化
//  3. 返回值变化
// 2. Provider的初始化参数
// 3. filter 相关信息
// 4. table 相关信息
// 5. page 分页信息
export type State = {
  id: string;
  /** 请求实体 */
  requestInfo?: RequestContext;
  /** 记录当前请求ID，取消请求时对比记录的与Context中的是否一致 */
  requestId?: string;
  /** 初始化存在的options */
  /** useQuery、resultKey、params  */
  initOptions?: {
    useQuery?: boolean;
    /** 根据url获取数据 */
    urlParams?: Record<string, any>;
    /** 设置url参数 */
    setUrlParams?: (params: Record<string, any>) => void;
  } & Pick<IQueryTableProviderProps, 'resultKey' | 'useQuery' | 'params'>;

  /** 表格信息 */
  table: {
    columns: TableColumnProps<any>[];
    selectedRowKeys?: string[];
    checkedColumnsKeys?: string[];
    data?: any;
  };
  /** 筛选框信息 */
  filter: {
    schema: QueryTableFilterISchemaProperties;
    components?: Record<string, any>;
    formOptions?: Omit<IFormProps, 'values' | 'initialValues'>;
    data?: Record<string, any>;
    form?: Form;
    gridProps?: IFormGridProps;
  };
  pagination: IPagination;
} & Required<Pick<IQueryTableProviderProps, 'resultKey' | 'loadClear'>>;

// 整体的动作 主要用于处理对state的处理
export enum ActionEnum {
  /** 分页跳转 */
  PAGINATION_SET = 1,
  /** 搜索操作 */
  REQUEST_START,
  /** 初始化第一次请求操作 */
  REQUEST_INIT_START,
  /** 重制操作 */
  FILTER_RESET,
  /** 请求成功 */
  REQUEST_RESPONSE_SUCCESS,
  /** 请求失败 */
  REQUEST_RESPONSE_FAIL,
  /** 取消请求操作 */
  REQUEST_CANCEL,
  /** 存储配置信息 */
  SAVE_OPTIONS,
  /** 清空table数据 */
  CLEAR_TABLE_DATA,
}

export type ActionType =
  | {
      // 分页
      type: ActionEnum.PAGINATION_SET;
      payload: Partial<Pick<IPagination, 'limit' | 'offset'>>;
    }
  | {
      // 搜索操作将filter筛选项的值 作为payload
      type: ActionEnum.REQUEST_START;
      payload: Record<string, any>;
    }
  | {
      // 请求开始
      type: ActionEnum.REQUEST_INIT_START;
    }
  | {
      // 重置请求
      type: ActionEnum.FILTER_RESET;
    }
  | {
      // 请求成功 修改requestInfo
      type: ActionEnum.REQUEST_RESPONSE_SUCCESS;
      payload: Partial<RequestContext>;
    }
  | {
      // 请求失败 修改requestInfo
      type: ActionEnum.REQUEST_RESPONSE_FAIL;
      payload: Partial<RequestContext>;
    }
  | {
      // 请求失败 修改requestInfo
      type: ActionEnum.REQUEST_CANCEL;
      payload: { requestId: string };
    }
  | {
      // 修改State
      type: ActionEnum.SAVE_OPTIONS;
      payload: Partial<State>;
    }
  | {
      // 清空table的data
      type: ActionEnum.CLEAR_TABLE_DATA;
    };
